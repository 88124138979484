@if (!this.doneLoading) {
<div class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'FLIGHTPICKERPAGE.retrieving_flights' | translate }}
</div>
} @if (this.doneLoading) {
<div style="max-width: 480px; margin: 10px auto">
	<mat-card class="mat-card-container">
		<mat-card-title class="mat-title">
			<span class="mat-title-span">{{ 'FLIGHTPICKERPAGE.choose_your_flight' | translate }}</span>
			<button mat-icon-button [routerLink]="['/']">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<div
				class="number-box"
				style="
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					width: 120px;
					margin: 0 auto;
					margin-top: 20px;
				"
			>
				<span>{{ upcomingFlights.length }}</span>
				<mat-icon>flight_takeoff</mat-icon>
				<span style="font-size: xx-small">{{ 'FLIGHTPICKERPAGE.flights_found' | translate | capitalize }}</span>
			</div>
			<div class="info-explanation">
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'FLIGHTPICKERPAGE.explanation' | translate : { childAge: '99' } }}</span>
			</div>
			<div
				*ngIf="countAvailableFlights(upcomingFlights) < upcomingFlights.length"
				style="display: flex; justify-content: center; align-items: center"
			>
				@if (showAll) {
				<button mat-raised-button (click)="showAll = !showAll" class="btn">
					<mat-icon *ngIf="showAll">filter_alt</mat-icon>
					{{ 'FLIGHTPICKERPAGE.filter' | translate | capitalize }} - ({{ countAvailableFlights(upcomingFlights) }}/{{
						upcomingFlights.length
					}})
				</button>
				} @else {
				<button mat-raised-button (click)="showAll = !showAll" class="btn">
					<mat-icon *ngIf="!showAll">filter_alt_off</mat-icon>
					{{ 'FLIGHTPICKERPAGE.filter_all' | translate | capitalize }} ({{ upcomingFlights.length }})
				</button>
				}
			</div>
			<mat-list>
				@for( flightOption of upcomingFlights; track $index) { @if (showAll || getFlightBookingStatus(flightOption) ===
				bookingStatus.Available) {
				<mat-divider></mat-divider>
				<div style="display: flex; justify-content: space-between; align-items: flex-start">
					<mat-list-item lines="3">
						@if (flightOption.passengerCounters.freeSpots >= passengersCount && flightOption.status != 'REA') {
						<mat-icon matListItemIcon>event_available</mat-icon>
						} @else {
						<mat-icon matListItemIcon>event_busy</mat-icon>
						}
						<span matListItemTitle>{{ getFormattedDate(flightOption.date) }} - {{ getHour(flightOption.hour) }}</span>
						<span matListItemLine>{{ flightOption.location.name }}</span>
					</mat-list-item>
					<ng-container [ngSwitch]="getFlightBookingStatus(flightOption)">
						<button
							*ngSwitchCase="bookingStatus.Available"
							mat-raised-button
							class="btn button-list"
							(click)="openConfirmationPopup($event, flightOption)"
						>
							{{ 'FLIGHTPICKERPAGE.request' | translate }}
						</button>
						<span *ngSwitchCase="bookingStatus.Expired" class="button-list">
							{{ 'FLIGHTPICKERPAGE.expiredBooking' | translate }}
						</span>
						<span *ngSwitchCase="bookingStatus.Full" class="button-list">
							{{ 'FLIGHTPICKERPAGE.flight_full' | translate }}
						</span>
						<span *ngSwitchCase="bookingStatus.TagRestricted" class="button-list">
							{{ 'FLIGHTPICKERPAGE.flight_restricted' | translate }}
						</span>
						<span *ngSwitchCase="bookingStatus.PartiallyFull" class="button-list">
							{{
								('FLIGHTPICKERPAGE.flight_partially_full' | translate).replace(
									'{0}',
									flightOption.passengerCounters.freeSpots <= 0 ? 0 : flightOption.passengerCounters.freeSpots
								)
							}}
						</span>
					</ng-container>
				</div>
				} }
			</mat-list>
		</mat-card-content>
	</mat-card>
</div>
}
