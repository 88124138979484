import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../_services/url.service';
import dayjs from 'dayjs';
import { Router } from '@angular/router';

export enum BookingStatus {
	Available = 'available',
	TagRestricted = 'tagRestricted',
	Expired = 'expiredBooking',
	Full = 'full',
	PartiallyFull = 'partiallyFull',
}

@Component({
	selector: 'app-flight-picker-page',
	templateUrl: './flight-picker-page.component.html',
	styleUrls: ['./flight-picker-page.component.css'],
})
export class FlightPickerPageComponent implements OnInit {
	upcomingFlights: Array<any> = [];
	showAll: boolean = true;
	myBookingId: string = '';
	myBooking: any;
	myTenantId: string = '';
	doneLoading: boolean = false;
	passengersCount: number = 0;
	bookingStatus = BookingStatus;

	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private urlService: UrlService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = myflight.booking.id;
				this.myBooking = myflight.booking;
				this.passengersCount = myflight.booking.passengers.length;
			}
		});
		this.urlService.getFlights().subscribe((result) => {
			if (result.myflightFlights) {
				this.upcomingFlights = result.myflightFlights;

				this.doneLoading = true;
			}
		});
	}

	openConfirmationPopup(e: any, flight: any) {
		e.stopPropagation();
		this.translate
			.get(['FLIGHTPICKERPAGE.confirmation_title', 'FLIGHTPICKERPAGE.confirmation_explanation'])
			.subscribe((trans) => {
				var confirmationData = {
					title:
						trans['FLIGHTPICKERPAGE.confirmation_title'] +
						' ' +
						this.getFormattedDate(flight.date) +
						' - ' +
						this.getHour(flight.hour) +
						'?',
					body: trans['FLIGHTPICKERPAGE.confirmation_explanation'],
				};
				var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
					data: confirmationData,
				});
				const sub = dialogRef.componentInstance.onYes.subscribe(() => {
					this.requestFlight(flight);
				});
				dialogRef.afterClosed().subscribe(() => {
					sub.unsubscribe();
				});
			});
	}

	requestFlight(flight: any) {
		this.doneLoading = false;
		this.urlService.addBookingToMyFlight(flight.id, this.myBookingId, this.myTenantId).subscribe(() => {
			this.doneLoading = true;
			this.navigateBack();
		});
	}

	getFormattedDate(date: any): string {
		return dayjs(date).format('ddd D MMM YYYY');
	}

	getHour(hour: any): string {
		return dayjs(hour).format('HH:mm');
	}

	expiredBooking(flightDate, bookingEndDate): boolean {
		if (!bookingEndDate) return false;
		return dayjs(flightDate).isAfter(bookingEndDate);
	}

	navigateBack() {
		this.router.navigate(['']);
	}

	getFlightBookingStatus(flight: any): BookingStatus {
		let bookingStatus = BookingStatus.Available;
		if (this.expiredBooking(flight.date, this.myBooking?.endDate)) {
			bookingStatus = BookingStatus.Expired;
			return bookingStatus;
		}
		if (flight.passengerCounters.freeSpots === 0 || flight.status === 'REA') {
			bookingStatus = BookingStatus.Full;
			return bookingStatus;
		}
		if (flight.passengerCounters.freeSpots < this.passengersCount) {
			bookingStatus = BookingStatus.PartiallyFull;
		}
		// check if booking tags are restricted
		let restricted = false;
		let allowedFlightTags = [];
		this.myBooking.tags.forEach((tag) => {
			tag.allowedFlightTags?.forEach((allowedTag) => {
				allowedFlightTags.push(allowedTag.id);
			});
		});
		let flightTags = flight?.tags.map((tag) => tag.id);

		if (allowedFlightTags.length === 0) {
			flight.tags.forEach((tag, index) => {
				if (index === 0) {
					restricted = tag.onlyAllowedFlightTags;
				} else {
					restricted = restricted && tag.onlyAllowedFlightTags;
				}
			});
		} else {
			restricted = true;
			allowedFlightTags.forEach((tag) => {
				if (flightTags.includes(tag)) {
					restricted = false;
				}
			});
		}
		if (restricted) {
			bookingStatus = BookingStatus.TagRestricted;
			return bookingStatus;
		}

		if (flight.passengerCounters.freeSpots >= this.passengersCount) {
			bookingStatus = BookingStatus.Available;
		}

		return bookingStatus;
	}
	countAvailableFlights(flights): number {
		return flights.filter((flight) => this.getFlightBookingStatus(flight) === BookingStatus.Available).length;
	}
}
