/* Hide element on extra small screens */
@media (max-width: 960px) {
	.hide-sm {
		display: none !important;
	}
}

/* Container for the split screen */
.split-screen {
	display: flex;
	gap: 10px;
	padding: 10px;
}

/* Left pane taking up 50% of the width */
.left-pane {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* Right pane taking up 50% of the width */
.right-pane {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* On small screens, make both columns 100% width */
@media (max-width: 959.98px) {
	.split-screen {
		flex-direction: column;
		gap: 10px;
	}

	.left-pane,
	.right-pane {
		flex: 1 1 100%;
		width: 100%;
	}
}
